import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-brands',
  templateUrl: './our-brands.component.html',
  styleUrls: ['./our-brands.component.scss']
})
export class OurBrandsComponent implements OnInit {
  title: string = "Our Brands";

  constructor() { }

  ngOnInit(): void {
  }

}
