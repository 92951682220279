<div class="header-wrapper">
    <div class="container">
        <div class="header-inner-wrapper">
            <div class="logo-wrapper">
                <a id="ajgLogo" (click)="navigateToMainPage()"><img src="../assets/images/rpp-logo.svg" alt="rpp-logo" /></a>
            </div>
            <div class="navbar-outer-wrapper">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="navbar-wrapper">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <img src="../assets/images/menu.svg" alt="menu" />
                        </button>
                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div class="close-menu-wrapper">
                                <button mat-icon-button class="close-menu" data-bs-dismiss="offcanvas">
                                    <img src="../assets/images/close.svg" alt="close-menu" />
                                </button>
                            </div>
                            <div class="offcanvas-body">
                              <ul class="navbar-nav justify-content-end flex-grow-1">
                                <!-- <li class="nav-item" id="brandsMenuItem">
                                    <a routerLink="/our-brands" data-bs-dismiss="offcanvas">Our Brands</a>
                                </li> -->
                                <li class="nav-item" id="helpMenuItem">
                                    <a routerLink="/help" data-bs-dismiss="offcanvas">Help & FAQs</a>
                                </li>
                                <li class="nav-item" *ngIf="isUserLoggedIn()">
                                    <a (click)="signOut()" id="exitMenuItem" data-bs-dismiss="offcanvas">Exit</a>
                                </li>
                              </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>